import NodeRSA from "node-rsa";
import pbkdf2 from "pbkdf2";

const client_key = new NodeRSA({ b: 512 });
export const key_256 = pbkdf2.pbkdf2Sync(
  "password",
  "salt",
  1,
  256 / 8,
  "sha512"
);
export const iv = pbkdf2.pbkdf2Sync("password", "salt", 1, 128 / 8, "sha512");

export default client_key;
